import React from "react";
import PageWithCmsWrapper from "../components/shared/page-with-cms-wrapper";
import { graphql } from "gatsby";

const CaseStudiesArticleTemplate = ({
                                      path,
                                      data: {
                                        strapiCaseStudiesArticles: {
                                          Title,
                                          author,
                                          content,
                                          created_at,
                                          SeoTitle,
                                          MetaDescriptions,
                                          tags,
                                          image,
                                        },
                                      },
                                    }) => {

  return (
    <PageWithCmsWrapper
      breadcrumbTitle={"Case Studies"}
      createDate={created_at}
      path={path}
      author={author}
      title={Title}
      content={content}
      image={image}
      seoProps={{ title: SeoTitle, description: MetaDescriptions, image: image }}
      tags={tags}
    />
  );
};

export default CaseStudiesArticleTemplate;


export const query = graphql`
  query CaseStudiesArticleTemplate($id: String!) {
    strapiCaseStudiesArticles(id: { eq: $id }) {
      id
      Title
      author {
        username
      }
      content
      created_at
      SeoTitle
      MetaDescriptions
      image
      tags {
        title
      }
    }
  }
`;
